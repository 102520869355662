import React, { useState, useEffect } from 'react';
import { AlertDataType } from './types';
import styles from './alert_popup.module.scss';

interface Props {
  alertData: null | AlertDataType;
  setAlertData: (param: null | AlertDataType) => void;
}

const AlertPopup = ({ alertData, setAlertData }: Props) => {
  const [iconName, setIconName] = useState('');

  useEffect(() => {
    if (!alertData) return;

    if (!alertData.sticky) {
      setTimeout(() => {
        setAlertData(null);
      }, alertData.timeoutValue || 8000);
    }

    switch(alertData.type) {
      case 'error':
        return setIconName('icon-times-circle');
      case 'warning':
        return setIconName('icon-exclamation-triangle');
      case 'success':
        return setIconName('icon-check');
      case 'info':
        return setIconName('icon-info-circle');
      default:
        return setIconName('icon-times-circle');
    }
  }, [alertData]);

  if (!alertData) return null;

  const { type, message } = alertData;

  return (
    <div className={styles.alertWrap} data-testid='alert-popup-component'>
      <div
        className={`${styles.alertContainer} ${styles[type]}`}
        role='alert'
        aria-label={`${type} alert`}
      >
        <div className={styles.textContent}>
          <i data-testid='alert-icon' className={`fal ${iconName} ${styles.icon}`} />
          <span>{message}</span>
        </div>
        <button
          className={styles.close}
          onClick={() => setAlertData(null)}
          aria-label={`close ${type} alert`}
          data-testid='close-alert-button'
        >
          <i className='fal icon-times' />
        </button>
      </div>
    </div>
  );
};

export default AlertPopup;
